import React from "react"
import WorkCard from "../reusable/WorkCard"
import { WorkCardContainer } from "../../styles/shared.styled"

const AllProjects = ({ data }) => {
  return (
    <>
      <WorkCardContainer>
        {data?.length > 0 && data?.map((
          {
            id,
            workTitle,
            workCategory,
            workCover,
            subCategory
          }, index) =>
          (
            <WorkCard
              key={id}
              id={id}
              title={workTitle}
              category={workCategory}
              url={workCover?.fluid}
              subCategory={subCategory}
              index={index}
            />
          ))}
      </WorkCardContainer>
    </>
  )
}

export default AllProjects
