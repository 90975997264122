export const mapSubCategories = (subCategories, category) => {
  if(!subCategories?.length) {
    return []
  }

  return subCategories.map((subCategory, idx) => ({
    id: idx,
    name: subCategory.replace('-', ' '),
    location: `/our-work/${category}/${subCategory}/`,
  }))

}