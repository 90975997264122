import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { Link } from "gatsby"
import AllProjects from "./AllProjects"

const MainContainer = styled(FlexContainer)`
    flex-direction: column;
`

const ScrollContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1150px;
    margin: 66px 0 77px;
    overflow: hidden;
`

const ScrollButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    height: 34px;
    width: 34px;
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.orange};
    cursor: pointer;
    border-radius: 12px;
    z-index: 1;
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`

const LeftScrollButton = styled(ScrollButton)`
  left: 0;
`

const RightScrollButton = styled(ScrollButton)`
  right: 0;
`

const TabContent = styled(FlexContainer)`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    max-width: 1150px;
    margin: 0;
    padding: 20px 10px;
    overflow-x: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
        justify-content: flex-start;
    }
`

const Title = styled(Link)`
    position: relative;
    font-size: 16px;
    white-space: nowrap;
    margin: 0;
    text-transform: uppercase;
    font-weight: ${({ selected }) => (selected ? "bold" : "100")};
    color: ${({ selected, theme: { white, orange } }) =>
            selected ? orange : white};
    cursor: pointer;
    transition: all 0.4s ease;
    text-decoration: none;

    &:first-child {
        margin-left: 34px; /* Adjust as needed */
    }

    &:last-child {
        margin-right: 34px; /* Adjust as needed */
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        height: 2px;
        top: 0;
        left: 0;
        transform: translate(50%, 30px);
        background: ${({ theme: { orange } }) => orange};
        visibility: ${({ selected }) => (selected ? "visible" : "hidden")};
    }
`

const Tabs = ({ tabs, currentTab = "", data }) => {
  const scrollRef = useRef(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)

  const handleScroll = (direction) => {
    const scrollAmount = 200
    if (scrollRef.current) {
      const newScrollLeft =
        direction === "left"
          ? scrollRef.current.scrollLeft  - scrollAmount
          : scrollRef.current.scrollLeft + scrollAmount

      scrollRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      })
    }
  }

  const updateScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
    }
  }

  useEffect(() => {
    updateScrollButtons()
    const scrollContainer =  scrollRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateScrollButtons)
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", updateScrollButtons)
      }
    }
  }, [])

  useEffect(() => {
      const element = document.getElementById(currentTab)
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        })
      }
  }, [currentTab])

  return (
    <MainContainer>
      <FlexContainer padding="0 20px">
        <ScrollContainer>
          <LeftScrollButton
            onClick={() => handleScroll("left")}
            show={canScrollLeft}
            disabled={!canScrollLeft}
          >
            &#9664; {/* Left arrow */}
          </LeftScrollButton>
          <TabContent ref={scrollRef}>
            {tabs?.map(({ id, name, location }) => (
              <Title
                activeClassName="active"
                to={location}
                key={id}
                selected={name === currentTab}
                id={name}
              >
                {name?.replaceAll('-', ' ')}
              </Title>
            ))}
          </TabContent>
          <RightScrollButton
            onClick={() => handleScroll("right")}
            show={canScrollRight}
            disabled={!canScrollRight}
          >
            &#9654; {/* Right arrow */}
          </RightScrollButton>
        </ScrollContainer>
      </FlexContainer>
      <AllProjects data={data} />
    </MainContainer>
  )
}

export default Tabs
