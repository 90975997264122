import React from "react"
import styled, { css } from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { P } from "../../styles/shared.styled"
import { useInView } from "react-intersection-observer"
import { navigate } from "gatsby-link"
import Image from "gatsby-image"

const StyledAnimateContainer = styled.div`
  position: relative;
  width: 49%;
  ${({ leftSide }) =>
          leftSide
                  ? css`
                    transform: ${({ inView }) =>
                            inView ? "translateX(0)" : "translateX(-440px)"};
                    opacity: ${({ inView }) => (inView ? "1" : "0")};
                  `
                  : css`
                    transform: ${({ inView }) =>
                            inView ? "translateX(0)" : "translateX(440px)"};
                    opacity: ${({ inView }) => (inView ? "1" : "0")};
                  `};

  transition: all 1s ease;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 48%;
    ${({ leftSide }) =>
            leftSide
                    ? css`
                      transform: ${({ inView }) =>
                              inView ? "translateX(0)" : "translateX(-200px)"};
                      opacity: ${({ inView }) => (inView ? "1" : "0")};
                    `
                    : css`
                      transform: ${({ inView }) =>
                              inView ? "translateX(0)" : "translateX(200px)"};
                      opacity: ${({ inView }) => (inView ? "1" : "0")};
                    `};
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const StyledP = styled(P)`
  position: absolute;
  bottom: ${({bottom}) => `${bottom}px`};
  z-index: 1;
`

const CardContainer = styled(FlexContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  background-size: cover;
  transform: scaleX(1);
  transition: all 0.3s ease;

  &:hover {
    transform: scaleX(1.03);
  }
  
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg,rgba(18,36,54,0.5) 24%,rgba(255,255,255,0) 100%);

    @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
      background: linear-gradient(0deg,rgba(18,36,54,0.5) 40%,rgba(255,255,255,0) 100%);
    }
    
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1.5;
  
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    aspect-ratio: 1;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    aspect-ratio: 1.5;
  }
`

const WorkCard = ({ title, category, index, id, url, subCategory }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
    initialInView: true
  })

  return (
    <StyledAnimateContainer
      ref={ref}
      inView={inView}
      leftSide={index % 2 === 0}
      onClick={() =>
        navigate(`/our-work/${category}/${subCategory}/${id}`)
      }
    >
      <CardContainer ref={ref}>
        <StyledImage
          loading="auto"
          fadeIn
          fluid={url}
        />
        <StyledP margin="0 0 10px" fontWeight="bold" color="white" bottom={64}>
          {title?.toUpperCase()}
        </StyledP>
        <StyledP fontSize="18px" color="white" bottom={32}>
          {subCategory?.toUpperCase()?.replaceAll('-', ' ')}
        </StyledP>
      </CardContainer>
    </StyledAnimateContainer>
  )
}

export default WorkCard
