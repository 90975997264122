import React from "react"
import LayoutRoot from "../components/LayoutRoot"
import styled from "styled-components"
import { FlexContainer } from "../styles/layout.styled"
import { useInView } from "react-intersection-observer"
import Tabs from "../components/OurWork/Tabs"
import { graphql } from "gatsby"
import { mapSubCategories } from "../utils/mappers/mapSubCategories"

const OurWorkContainer = styled(FlexContainer)`
  flex-direction: column;
`

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  margin: 70px 0;
  text-transform: uppercase;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0) scale(1)" : "translateX(500px) scale(0)"};
  transition: all 1s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(250px) scale(0)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(100px) scale(0)"};
  }
`

const PageContent = styled(FlexContainer)`
  background: #132436;
`

const OurWorkPage = props => {
  const { pageContext, data } = props || {}
  const { subCategories, subCategory, category } = pageContext || {}
  const { ref: titleRef, inView: titleInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })
  const allProjectsOrCategories = data?.work?.nodes

  return (
    <LayoutRoot>
      <OurWorkContainer>
        <FlexContainer bg="#193048" padding="81px 0 0">
          <PageTitle ref={titleRef} inView={titleInView}>
            {category?.replaceAll('-', ' ')}
          </PageTitle>
        </FlexContainer>
        <PageContent>
          <Tabs
            tabs={mapSubCategories(subCategories, category)}
            currentTab={subCategory}
            data={allProjectsOrCategories}
          />
        </PageContent>
      </OurWorkContainer>
    </LayoutRoot>
  )
}

export default OurWorkPage

export const query = graphql`
  query ($genre: String!, $category: String!) {
    work: allContentfulOurWork(filter: { subCategory: { eq: $genre }, workCategory: {eq: $category} }) {
      nodes {
        workTitle
        id
        workCategory
        description
        subCategory
        workCover {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
    }
  }
`
